import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-03";
import ClientFacts from "@containers/language-translation/client-facts/layout-02";
import Results from "@containers/language-translation/client-facts";
import OurSolution from "@containers/language-translation/our-solution";

const CaseWarner = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Case study - BorgWarner"
        description="BorgWarner Inc. is an American automotive supplier headquartered in Auburn Hills, Michigan. The company maintains production facilities and technical systems at 93 sites in 22 countries worldwide and has around 49,000 employees. BorgWarner is one of the 25 largest automotive suppliers in the world."
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader layout={11} data={content["warner-header-section"]} />
        <ClientFacts layout={2} data={content["warner-client-challenges"]} />
        <OurSolution layout={8} data={content["warner-solutions"]} />
        <Results data={content["warner-results"]} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query caseCaseWarnerPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "use-cases" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

CaseWarner.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default CaseWarner;
